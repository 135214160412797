// @flow

import type { Node } from 'react'
import React from 'react'
import { map } from 'ramda'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../components/layout'
import { Heading1, BodyText } from '../components'

import { renderPolaroidImage } from '../helpers/contentfulPageImageRenderer'
import type { PolaroidImage as PolaroidImageType } from '../types'

type QueryResponse = {|
  ftMyersBeachPage: {
    images: PolaroidImageType[],
  },
|}

function FtMyersBeachInfo(): Node {
  const data: QueryResponse = useStaticQuery(graphql`
    query FtMyersBeachInfoQuery {
      ftMyersBeachPage: contentfulPage(slug: { eq: "FtMyersBeachInfo" }) {
        images {
          id
          description
          image: fixed(width: 800, quality: 60) {
            src
          }
        }
      }
    }
  `)

  const images = map(renderPolaroidImage, data.ftMyersBeachPage.images)
  const renderImages =
    images.length > 0 ? <div id="gallery">{images}</div> : null

  return (
    <Layout>
      <div className="bg-purple text-white">
        <div className="pt-12 pb-2 md:pt-16 px-8">
          <div className="w-full max-w-xl mx-auto">
            <div className="mb-6 md:max-w-md md:mx-auto">
              <Heading1 className="mb-4" isTitle>
                Beautiful Fort Myers Beach
              </Heading1>
              <BodyText className="leading-normal text-purple-lightest">
                Whether it’s dining at a local bistro, taking a stroll through
                the pedestrian shopping district, or simply relaxing on the
                white sandy beaches, you’ll love Ft. Myers Beach. There’s always
                a balmy breeze blowing gently off the water. Full of color,
                wildlife, and local uniqueness, Ft. Myers Beach is a place you
                will return to… again and again.
              </BodyText>
            </div>
            {renderImages}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FtMyersBeachInfo
